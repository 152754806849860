import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import AccountPanel from "../../components/AccountPanel";
import * as CognitoService from "../../services/cognito.service";

export default function Security() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    CognitoService.getUserAttributes()
      .then((result) => setEmail(result.email))
      .catch((error) => {});
  }, []);

  return (
    <div>
      <AccountPanel />

      <div className="side-content">
        <h2>Account Security</h2>

        <h3>How you sign in</h3>
        <div className="form-container">
          <div className="split-group">
            <span className="title">Password sign in is enabled</span>
            <a className="action success" role="button" href="/reset-password">
              Change password
            </a>
          </div>
        </div>

        <h3>Contact Details</h3>
        <Box sx={{ mt: 4 }}>
          <TextField
            size="small"
            variant="outlined"
            name="email"
            id="email"
            label="Email address"
            value={email || ""}
            fullWidth
            disabled
          />
        </Box>
      </div>
    </div>
  );
}
